import { takeEvery, call, put } from "redux-saga/effects"
import { CREATE_USER, GET_USERS, GET_USER, DELETE_USER } from "./actionTypes"
import {
  apiError,
  apiSuccess,
  onCreateUser,
  onGetUsers,
  onGetUser,
  onDeleteUser,
  userEntries,
} from "./actions"
import { db, auth } from "../../config/firebaseConfig"
import {
  collection,
  addDoc,
  Timestamp,
  getDoc,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore"
import {
  createUserWithEmailAndPassword,
  signOut,
  deleteUser,
} from "firebase/auth"

const collectionName = "Users"
const collectionRef = collection(db, collectionName)

function* createUser({ payload: { user, history } }) {
  try {
    yield call(createUserWithEmailAndPassword, auth, user.email, user.password)
    const userId = yield auth.currentUser.uid
    yield call(signOut, auth)
    const docRef = doc(collectionRef, userId)
    const data = {
      email: user.email,
      role: user.role,
      totalPayed: 0,
      name: user.name,
      uid: docRef.id,
    }
    yield call(setDoc, docRef, data)
    yield put(apiSuccess("Created Successfully"))
    yield put(onCreateUser(data))
  } catch (err) {
    yield put(apiError(err.message))
  }
}
function* getUsers() {
  try {
    const users = yield call(getDocs, collectionRef)
    yield put(onGetUsers(users.docs.map(doc => doc.data())))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* deleteUserSaga({ payload }) {
  try {
    // const deleteUserAccount = yield call(deleteUser, payload)
    // console.log(deleteUserAccount)
    const userDoc = doc(db, collectionName, payload)
    yield call(deleteDoc, userDoc)
    yield put(onDeleteUser(payload))
    yield put(apiSuccess("Deleted Successfully"))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getSelectedUser({ payload }) {
  try {
    const docRef = doc(db, collectionName, payload)
    const getUser = yield call(getDoc, docRef)
    const user = getUser.data()
    const entriesRef = collection(db, collectionName, user.user_id, "Entery")
    const entries = yield call(getDocs, entriesRef)

    const entriesData = entries.docs.map(doc => doc.data())
    yield put(userEntries(entriesData))
    yield put(onGetUser(user))
  } catch (err) {
    console.log(err.message)
    yield put(apiError(err.message))
  }
}

function* saga() {
  yield takeEvery(CREATE_USER, createUser)
  yield takeEvery(GET_USERS, getUsers)
  yield takeEvery(GET_USER, getSelectedUser)
  yield takeEvery(DELETE_USER, deleteUserSaga)
}

export default saga
