import {
  CREATE_USER,
  ON_CREATE_USER,
  GET_USERS,
  ON_GET_USERS,
  GET_USER,
  ON_GET_USER,
  API_ERROR,
  API_SUCCESS,
  USER_ENTRIES,
  DELETE_USER,
  ON_DELETE_USER,
} from "./actionTypes"

export const createUser = data => {
  return {
    type: CREATE_USER,
    payload: data,
  }
}

export const onCreateUser = data => {
  return {
    type: ON_CREATE_USER,
    payload: data,
  }
}

export const getUsers = data => {
  return {
    type: GET_USERS,
    payload: {},
  }
}

export const onGetUsers = data => {
  return {
    type: ON_GET_USERS,
    payload: data,
  }
}

export const getUser = data => {
  return {
    type: GET_USER,
    payload: data,
  }
}

export const onGetUser = data => {
  return {
    type: ON_GET_USER,
    payload: data,
  }
}

export const userEntries = data => {
  return {
    type: USER_ENTRIES,
    payload: data,
  }
}

export const deleteUser = data => {
  return {
    type: DELETE_USER,
    payload: data,
  }
}

export const onDeleteUser = data => {
  return {
    type: ON_DELETE_USER,
    payload: data,
  }
}

export const apiSuccess = message => {
  return {
    type: API_SUCCESS,
    payload: message,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
