import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { createUser } from "../../store/users/actions"
import { useHistory } from "react-router"
import UsersTable from "./UsersTable"
const breadcrumbItems = [
  { title: "Easy App", link: "#" },
  { title: "Users", link: "#" },
]

function UsersMain() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [userModal, setUserModal] = useState(false)

  const { btnLoadinng } = useSelector(state => ({
    btnLoadinng: state.Users.btnLoading,
  }))

  /* User Modal */
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  })

  useEffect(() => {
    dispatch(setBreadcrumbItems("Users", breadcrumbItems))
  }, [])

  const handleSubmit = () => {
    if (user.name && user.email && user.role && user.password) {
      dispatch(createUser({ user, history }))
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Users | Admin Dashboard</title>
      </MetaTags>

      <Modal isOpen={userModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add User</h5>
          <button
            type="button"
            onClick={() => {
              setUserModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div className="form-group">
                  <label htmlFor="exampleInput">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={e => {
                      setUser(data => ({ ...data, name: e.target.value }))
                    }}
                    value={user.name}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div className="form-group">
                  <label htmlFor="exampleInput">Email</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={e => {
                      setUser(data => ({ ...data, email: e.target.value }))
                    }}
                    value={user.email}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div className="form-group">
                  <label htmlFor="exampleInput">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    onChange={e => {
                      setUser(data => ({ ...data, password: e.target.value }))
                    }}
                    value={user.password}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/* Select User Role */}
              <div className="mb-3">
                <div className="form-group">
                  <label htmlFor="exampleInput">Select Role</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={user.role || ""}
                    onChange={e => {
                      setUser(prev => ({ ...prev, role: e.target.value }))
                    }}
                  >
                    <option disabled value="">
                      Select User Type
                    </option>
                    <option value="Employee">Employee</option>
                    <option value="Manager">Manager</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
              </div>
            </Col>
          </Row>
          {!btnLoadinng ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Add User
            </button>
          ) : (
            <button type="button" className="btn btn-primary" disabled>
              Adding User...
            </button>
          )}
        </div>
      </Modal>

      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setUserModal(true)}
      >
        Create User
      </button>
      <UsersTable />
    </React.Fragment>
  )
}

export default UsersMain
