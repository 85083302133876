import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

function* loginUser({ payload: { user, history } }) {
  try {
    if (
      user.email === process.env.REACT_APP_LOGIN_EMAIL &&
      user.password === process.env.REACT_APP_LOGIN_PASSWORD
    ) {
      localStorage.setItem("authUser", JSON.stringify(user))
      // yield put(loginSuccess(response))
      yield put(loginSuccess(""))
      history.push("/dashboard")
    } else {
      throw new Error("Invalid Email or Password")
    }
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess(""))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
