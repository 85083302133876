import React, { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getUser } from "../../store/users/actions"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Spinner,
} from "reactstrap"
import Geocode from "react-geocode"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)
Geocode.setLanguage("en")
Geocode.setLocationType("ROOFTOP")

function UsersDetail() {
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUser(params.id))
  }, [])

  const [entries, setEntries] = useState([])

  const { loading, entriesData } = useSelector(state => ({
    entriesData: state.Users.entries,
    loading: state.Users.loading,
  }))

  useEffect(() => {
    if (entriesData.length > 0) {
      Promise.all(
        entriesData.map(async entry => {
          const checkOutLocation = await Geocode.fromLatLng(
            entry.checkOutGeoPoint._lat,
            entry.checkOutGeoPoint._long
          )
          const checkInLocation = await Geocode.fromLatLng(
            entry.checkInGeoPoint._lat,
            entry.checkInGeoPoint._long
          )
          const checkInTime = entry.checkintimeStamp.toDate().getTime()
          const checkOutTime = entry.checkouttimeStamp.toDate().getTime()
          const time = checkOutTime - checkInTime
          const timeInHours = time / (1000 * 3600)
          const user = location.state.user
          const pay = timeInHours * user.user_perhour_pay

          return {
            ...entry,
            checkOutLocation: checkOutLocation.results[0].formatted_address,
            checkInLocation: checkInLocation.results[0].formatted_address,
            pay: pay.toFixed(3),
          }
        })
      ).then(data => {
        setEntries(data)
      })
    }
  }, [entriesData])

  return (
    <div>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Enteries</h4>

              <div className="card-description">
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table tample-sm table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th data-priority="1">No</Th>
                          <Th data-priority="2">
                            Check-In <small>(TimeStamp)</small>
                          </Th>
                          <Th data-priority="2">
                            Check-Out <small>(TimeStamp)</small>
                          </Th>
                          <Th data-priority="2">
                            Check-In <small>(Location)</small>
                          </Th>
                          <Th data-priority="2">
                            Check-Out <small>(Location)</small>
                          </Th>
                          <Th>Pay Per Day</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {!loading
                          ? entries && entries.length > 0
                            ? entries.map((item, index) => {
                                {
                                  return (
                                    <Tr key={index}>
                                      <Th>{index + 1}</Th>
                                      <Th>
                                        {item.checkintimeStamp
                                          .toDate()
                                          .toString()}
                                      </Th>
                                      <Th>
                                        {item.checkouttimeStamp
                                          .toDate()
                                          .toString()}
                                      </Th>
                                      <Th>{item.checkInLocation}</Th>
                                      <Th>{item.checkOutLocation}</Th>
                                      <Th>{item.pay}</Th>
                                    </Tr>
                                  )
                                }
                              })
                            : null
                          : null}
                      </Tbody>
                    </Table>
                    {loading && (
                      <div className="d-flex justify-content-center p-2">
                        <Spinner animation="border" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UsersDetail
