import {
  CREATE_USER,
  ON_CREATE_USER,
  GET_USERS,
  ON_GET_USERS,
  GET_USER,
  ON_GET_USER,
  API_ERROR,
  API_SUCCESS,
  USER_ENTRIES,
  DELETE_USER,
  ON_DELETE_USER,
} from "./actionTypes"

const initialState = {
  data: [],
  entries: [],
  error: "",
  success: "",
  loading: false,
  btnLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_CREATE_USER: {
      state = {
        ...state,
        btnLoading: false,
        data: [...state.data, action.payload],
      }
      break
    }
    case GET_USERS: {
      state = { ...state, loading: true }
      break
    }
    case ON_GET_USERS: {
      state = { ...state, loading: false, data: action.payload }
      break
    }
    case GET_USER: {
      state = {
        ...state,
        loading: true,
        entries: [],
      }
      break
    }
    case DELETE_USER: {
      state = { ...state, loading: true }
      break
    }
    case ON_DELETE_USER: {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(item => item.uid != action.payload),
      }
      break
    }
    case ON_GET_USER: {
      state = {
        ...state,
      }
      break
    }
    case USER_ENTRIES: {
      state = {
        ...state,
        entries: action.payload,
        loading: false,
      }
      break
    }

    case API_ERROR: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        error: action.payload,
      }
      break
    }
    case API_SUCCESS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: action.payload,
      }
      break
    }
    default: {
      state = { ...state }
    }
  }
  return state
}

export default reducer
