export const CREATE_USER = "CREATE_USER"
export const ON_CREATE_USER = "ON_CREATE_USER"

export const GET_USERS = "GET_USERS"
export const ON_GET_USERS = "ON_GET_USERS"

export const GET_USER = "GET_USER"
export const ON_GET_USER = "ON_GET_USER"

export const DELETE_USER = "DELETE_USER"
export const ON_DELETE_USER = "ON_DELETE_USER"

export const USER_ENTRIES = "USER_ENTRIES"

export const API_ERROR = "API_ERROR"
export const API_SUCCESS = "API_SUCCESS"
