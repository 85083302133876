import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getUsers,
  deleteUser,
  apiError,
  apiSuccess,
} from "../../store/users/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast, Slide } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
function UsersTable() {
  const dispatch = useDispatch()
  const { loading, data, success, error } = useSelector(state => {
    return {
      loading: state.Users.loading,
      data: state.Users.data,
      success: state.Users.success,
      error: state.Users.error,
    }
  })
  const [deleteUserId, setDeleteUserId] = useState("")
  const [deleteAlert, setDeleteAlert] = useState(false)
  useEffect(() => {
    dispatch(getUsers())
  }, [])
  const deleteUserHandler = () => {
    if (deleteUserId) {
      dispatch(deleteUser(deleteUserId))
    }
  }

  /* notifications */
  function notify({ success, error }) {
    const options = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    if (success) {
      toast.success(success, options)
      dispatch(apiSuccess(""))
    }
    if (error) {
      toast.error(error, options)
      dispatch(apiError(""))
    }
  }

  return (
    <React.Fragment>
      {success && notify({ success })}
      {error && notify({ error })}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      <Row className="my-3">
        {deleteAlert && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteAlert(false)
              deleteUserHandler()
            }}
            onCancel={() => setDeleteAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        )}

        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Users Detail</CardTitle>
              <p className="card-title-desc">
                You can view the detail of User Data in Table.
              </p>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">No</Th>
                        <Th data-priority="2">Name</Th>
                        <Th data-priority="3">Email</Th>
                        <Th data-priority="4">Role</Th>
                        <Th data-priority="1">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data &&
                        data.length > 0 &&
                        data.map((user, index) => {
                          return (
                            <Tr key={index}>
                              <Th>{index + 1}</Th>
                              <Th>{user.name}</Th>
                              <Th>{user.email}</Th>
                              <Th>{user.role}</Th>
                              <Th>
                                <i
                                  className="mdi mdi-delete mx-2 font-size-18"
                                  id="edittooltip"
                                  style={{
                                    cursor: "pointer",
                                    color: "#ff5252",
                                  }}
                                  onClick={() => {
                                    setDeleteUserId(user.uid)
                                    setDeleteAlert(true)
                                  }}
                                />
                              </Th>
                            </Tr>
                          )
                        })}
                    </Tbody>
                  </Table>
                  {loading && (
                    <div className="d-flex justify-content-center p-2">
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!loading && !data.length > 1 && (
                    <div className="d-flex justify-content-center p-2">
                      <h5 className="text-secondry">Empty</h5>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default UsersTable
